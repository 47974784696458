import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Global } from "@emotion/core"

import Layout from "../../layouts"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Title from "../../components/hero/title"
import Author from "../../components/blog/author"
import FlexibleContentBlock from "../../components/blog/flexible-content-block"
import Meta from "../../components/blog/meta"
import PrevAndNext from "../../components/blog/prev-and-next"
import Footer from "../../components/footer"
import config from "../../config"

import { defaultPaddingHorizontal } from "../../utils/styles"
import { globalStyles } from "../../utils/styles/global"

/*
  Returns url from siteMetadata, stripped of protocal and trailing slash
*/
const getFormattedHostUrl = () => {
  const url = config.url.replace(/https:\/\//, "")
  return url.endsWith("/") ? url.slice(0, -1) : url
}

/*
  Returns URL of blog post social media image, if it exists. The SEO component requires that this URL is in the format '//hostname.com/path'
*/
const getSocialMediaImageUrl = post => {
  const imagePath = post.seoMetadata?.socialMediaImage?.localFile?.publicURL

  if (!imagePath) {
    return null
  }

  const host = getFormattedHostUrl()
  return `//${host}${imagePath}`
}

const BlogPostPage = ({
  location,
  data: { wpPost: post },
  pageContext: { next, prev },
}) => {
  const socialMediaImageUrl = getSocialMediaImageUrl(post)
  return (
    <Layout pathname={location.pathname}>
      <Global styles={globalStyles} />
      <SEO
        title={post.seoMetadata.seoTitle ?? post.title}
        description=""
        url={location.href}
        socialMediaImageUrl={socialMediaImageUrl}
      />
      <Header location={location.pathname} />
      <main
        id={`gatsby-skip-here`}
        className="post docSearch-content"
        css={theme => [
          {
            marginLeft: `auto`,
            marginRight: `auto`,
            paddingBottom: theme.space[10],
            paddingTop: theme.space[10],
            [theme.mediaQueries.phablet]: {
              display: "grid",
              gridTemplateColumns: "1fr",
              maxWidth: "42rem",
              width: `90%`,
            },
            [theme.mediaQueries.desktop]: {
              display: "grid",
              gridColumnGap: theme.space[13],
              gridTemplateColumns: "1fr 15rem",
              maxWidth: "68rem",
              paddingBottom: theme.space[13],
              paddingTop: theme.space[13],
            },
            [theme.mediaQueries.hd]: {
              gridTemplateColumns: "1fr 18rem",
            },
          },
          defaultPaddingHorizontal(theme),
        ]}
      >
        <Title
          css={theme => ({
            alignSelf: "end",
            [theme.mediaQueries.phablet]: {
              maxWidth: "90%",
            },
            [theme.mediaQueries.desktop]: {
              marginBottom: 0,
            },
          })}
        >
          {post.title}
        </Title>
        <div
          css={theme => ({
            order: 0,
            [theme.mediaQueries.desktop]: {
              alignSelf: "end",
              gridColumn: 2,
              gridRow: 1,
              marginTop: 48,
              position: "relative",
            },
          })}
        >
          <Author
            name={post.author?.node?.name || `Gatsby Contributor`}
            date={post.date}
            slug={post.author?.node?.slug}
            image={
              post.author?.node?.authorInfo?.avatar?.localFile.childImageSharp
                .resize.src
            }
          />
        </div>
        <section
          className="post-body main-body"
          css={theme => ({
            gridColumn: 1,
            minWidth: 0,
            paddingTop: theme.space[8],
            ".gatsby-resp-iframe-wrapper": {
              height: 0,
              overflow: `hidden`,
              paddingBottom: `56.25%`,
              position: `relative`,
              width: `100%`,
              iframe: {
                height: `100%`,
                left: 0,
                position: `absolute`,
                top: 0,
                width: `100%`,
              },
            },
            ".gatsby-embed-twitter": {
              maxHeight: `1000px`,
              overflowY: `auto`,
            },
          })}
        >
          {post.featuredImage?.node.localFile?.childImageSharp?.id && (
            <Img
              alt={post.featuredImage.node.altText}
              fluid={post.featuredImage.node.localFile.childImageSharp.fluid}
            />
          )}
          {post.flexibleContent?.blocks?.map((block, index) => {
            return (
              <div
                key={index}
                css={theme => ({
                  marginBottom: theme.space[6],
                  paddingTop: theme.space[6],
                })}
              >
                <FlexibleContentBlock block={block} />
              </div>
            )
          })}
        </section>
        <div
          css={theme => ({ [theme.mediaQueries.desktop]: { gridColumn: 2 } })}
        >
          <Meta post={post} />
        </div>
        <div
          css={theme => ({ [theme.mediaQueries.desktop]: { gridColumn: 1 } })}
        >
          <PrevAndNext prev={prev} next={next} />
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default BlogPostPage

export const pageQuery = graphql`
  query BlogPage($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      featuredImage {
        node {
          id
          altText
          localFile {
            childImageSharp {
              id
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      rawExcerpt {
        text
      }
      author {
        node {
          ...AuthorInformation
        }
      }
      seoMetadata {
        canonicalLink
        publishedAt
        seoTitle
        socialMediaImage {
          localFile {
            publicURL
          }
        }
      }
      ctaRightRail {
        ctaLink
        ctaText
        ctaTitle
      }
      tags {
        nodes {
          id
          name
          slug
        }
      }
      date(formatString: "MMMM Do, YYYY")
      ...FlexibleContentBlocks
    }
  }
`
