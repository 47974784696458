import React from "react"
import { AuthorAvatar, Name } from "../../components/blog/author"
import { visuallyHidden } from "../../utils/styles"
import { ctaBtnCss } from "../shared/styles"
import Tags from "../../components/blog/tags"
import { LinkButton } from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"

const CanonicalLinkCss = theme => ({
  borderBottom: `1px solid ${theme.colors.standardLine}`,
  color: theme.colors.text.secondary,
  fontSize: theme.fontSizes[0],
  marginBottom: 0,
  paddingBottom: theme.space[6],
  paddingTop: theme.space[6],
  [theme.mediaQueries.desktop]: {
    gridColumn: 1,
    order: 0,
  },
})

const actionsCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  marginTop: theme.space[9],
  marginBottom: theme.space[0],

  "& > :first-child": {
    marginBottom: theme.space[0],
  },

  [theme.mediaQueries.phablet]: {
    flexDirection: `row`,

    "& > :first-child": {
      marginBottom: 0,
      marginRight: theme.space[4],
    },
  },
})

// this component renders
// - a "Written by [author]" block if there's a bio available for the author
// - canonical link information ("Originally posted at…")
// - post tags
const Meta = ({ post }) => {
  const {
    ctaRightRail: {
      ctaTitle = `Talk to our team of Gatsby Experts to supercharge your website
    performance.`,
      ctaText = `Contact Gatsby Now`,
      ctaLink = `/concierge#contact`,
    },
  } = post
  return (
    <>
      {post.author?.node?.authorInfo?.bio && (
        <div
          css={theme => ({
            marginBottom: theme.space[10],
            [theme.mediaQueries.desktop]: {
              marginTop: theme.space[10],
            },
          })}
        >
          <div
            css={theme => ({
              borderTop: `1px solid ${theme.colors.standardLine}`,
              display: "flex",
              marginTop: theme.space[10],
              paddingTop: theme.space[10],
              [theme.mediaQueries.desktop]: { ...visuallyHidden },
            })}
          >
            <div css={{ flex: `0 0 auto` }}>
              <AuthorAvatar
                name={post.author?.node?.name || `Gatsby Contributor`}
                slug={post.author?.node?.slug}
                image={
                  post.author?.node?.authorInfo?.avatar?.localFile
                    .childImageSharp.resize.src
                }
              />{" "}
            </div>
            <div css={{ flex: `1 1 auto` }}>
              <span
                css={theme => ({
                  fontSize: theme.fontSizes[1],
                  color: theme.colors.text.secondary,
                })}
              >
                Written by
              </span>
              <h6
                css={theme => ({
                  fontFamily: theme.fonts.body,
                })}
              >
                <Name
                  slug={post.author?.node?.slug}
                  name={post.author?.node?.name || `A Gatsby Contributor`}
                />
              </h6>
            </div>
          </div>
          <div
            css={theme => ({
              color: theme.colors.text.secondary,
              fontSize: theme.fontSizes[1],
              [theme.mediaQueries.desktop]: {
                fontSize: theme.fontSizes[0],
              },
            })}
          >
            <p>{post.author.node.authorInfo.bio}</p>
            {post.author?.node?.authorInfo?.twitter && (
              <p>
                <a
                  href={`https://www.twitter.com/${post.author.node.authorInfo.twitter}`}
                >
                  Follow {post.author?.node?.name} on Twitter
                </a>
              </p>
            )}
            <Tags tags={post.tags.nodes} />
          </div>
        </div>
      )}
      <div
        css={theme => ({
          borderTop: `1px solid ${theme.colors.standardLine}`,
          [theme.mediaQueries.desktop]: {
            marginTop: theme.space[10],
          },
        })}
      >
        {post.seoMetadata.canonicalLink && (
          <p css={CanonicalLinkCss}>
            Originally published at
            {` `}
            <a href={post.seoMetadata.canonicalLink}>
              {post.seoMetadata.publishedAt ??
                post.seoMetadata.canonicalLink
                  .replace(/^(?:https?:\/\/)?(?:www\.)?|\/$/i, "")
                  .replace(/\/+$/, "")}
            </a>
          </p>
        )}
      </div>
      <h3
        css={theme => ({
          fontFamily: theme.fonts.body,
        })}
      >
        {ctaTitle}
      </h3>
      <div css={actionsCss}>
        <LinkButton
          href={ctaLink}
          size="L"
          css={theme => ctaBtnCss({ theme, tone: `cloud` })}
          rightIcon={<MdArrowForward />}
        >
          {ctaText}
        </LinkButton>
      </div>
    </>
  )
}

export default Meta
